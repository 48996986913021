.pending_modal_box {
    background: #FFFFFF !important;

    @media(max-width: 450px) {
        width: 85% !important;
        min-width: 0px !important;
    }
}

.request_avatar {
    width: 56px;
    height: 56px;

    @media(max-width: 450px) {
        width: 48px;
        height: 48px;
    }
}

.decline_modal_box {
    background: #FFFFFF !important;
    max-height: 577px !important;
    overflow-y: scroll !important;
    &::-webkit-scrollbar {
        width: 0px;
    }
    @media(max-width: 450px) {
        width: 85% !important;
        min-width: 0px !important;
    }
}

.pending_box {
    padding: 16px 24px !important;
    background: #5497FF !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    display: flex !important;
    justify-content: space-between !important;

    @media(max-width: 450px) {
        padding: 12px !important;
    }
}

.decline_arrow_button {
    font-size: 24px;
    color: #FFFFFF;

    @media(max-width: 450px) {
        font-size: 18px !important;
    }
}

.holding_request_container {
    align-items: center;
    display: flex;
    margin-top: auto;
    padding: 12px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    justify-content: space-between;
    box-shadow: -2px -3px 8px 0px rgba(0, 0, 0, 0.08);
    
    @media(max-width: 450px) {
        padding: 12px !important;
    }
}

.reason_avatar_container {
    display: flex;
}

.decline_box {
    padding: 16px 24px !important;
    background: #5497FF !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;

    @media(max-width: 450px) {
        padding: 12px !important;
    }
}

.pending_request_text {
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 600 !important;
    line-height: 22px !important;

    @media(max-width: 450px) {
        color: #FFFFFF !important;
        font-size: 14px !important;
        font-family: "Nunito-Regular" !important;
        font-weight: 600 !important;
        line-height: 18px !important;
    }
}

.decline_request_text {
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    margin: 0px 0px 0px 12px !important;

    @media(max-width: 450px) {
        color: #FFFFFF !important;
        font-size: 14px !important;
        font-family: "Nunito-Regular" !important;
        font-weight: 600 !important;
        line-height: 18px !important;
        margin: 0px 0px 0px 6px !important;
    }
}

.no_convo_text {
    color: #475569 !important;
    font-size: 12px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;
    line-height: 18px !important;
}

.no_convo_container {
    align-self: center;
    justify-self: center;
}

.member_name_text {
    color: #475569 !important;
    font-size: 16px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    margin-left: 16px !important;
    word-break: break-word !important;
    @media(max-width: 450px) {
        color: #475569 !important;
        font-size: 14px !important;
        font-family: "Nunito-Regular" !important;
        font-weight: 600 !important;
        line-height: 18px !important;
        margin-left: 8px !important;
    }

}

.requests-section {
    background-color: #FFFFFF !important;
    padding: 8px 24px !important;
    max-height: 505px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #CFCFCF;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F1F5F9;
        border-radius: 4px;
    }

    @media(max-width: 450px) {
        padding: 0px 12px !important;
    }
}

.reason_input_base {
    width: 330px !important;
    margin-left: 6px !important;
    @media(max-width: 450px) {
        width: 180px !important;
    }
    .MuiInputBase-input {
        color: #334155 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        font-family: "Nunito-Regular" !important;

        @media(max-width: 450px) {
            font-size: 12px !important;
            padding-left: 6px !important;
        }
    }
}

.reason-send-button {
    min-width: 0px !important;
    padding: 0px !important;

    .MuiButton-label {
        padding: 0px !important;
        font-family: "Nunito-Regular" !important;
        font-size: 16px !important;
        color: #5497FF !important;
        font-weight: 600 !important;
        line-height: 18px !important;

        @media (max-width: 450px) {
            font-size: 12px !important;
        }

        .MuiButton-text {
            padding: 0px !important;
        }
    }
}

.reason_avatar {
    width: 40px;
    height: 40px;

    @media(max-width: 450px) {
        width: 32px !important;
        height: 32px !important;
    }
}

.conversation-section {
    padding: 8px 24px !important;
    max-height: 400px !important;
    min-height: 383px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
    align-items: center !important;
    justify-content: center;

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #CFCFCF;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F1F5F9;
        border-radius: 4px;
    }
}

.hold_icon_container {
    margin-left: 8px !important;
    cursor: pointer;
}

.tick_icon_container {
    cursor: pointer;
}

.cross_icon_container {
    margin-left: 8px !important;
    cursor: pointer;
}

.member_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
}

.member_basic_info {
    display: flex;
    align-items: center;
}

.decline_container {
    display: flex;
    align-items: center;
}

.divider_container {
    display: flex;
    justify-content: center;
}

.divider {
    background-color: #CBD5E1;
    height: 0.1px;
    width: 100%;
    border: none
}

.pending_conversation_container {
    width: 11px;
    height: 11px;
    justify-content: center;
    align-items: center;
    background: #F87171;
    border-radius: 50px;
    display: flex !important;
    position: absolute !important;
    margin: 0px 0px 0px 13px !important;
}

.pending_conversation_number {
    font-family: "Nunito-Regular" !important;
    font-size: 7px !important;
    font-weight: 500 !important;
    line-height: 9.55px !important;
    color: #ffffff !important;
    padding-top: 1px !important;

}