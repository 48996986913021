.signup-box {
    display: flex;
    width: 100%;
    background: linear-gradient(179.98deg, #E7EFFC 0.01%, #FFFFFF 99.99%);
    min-height: 600px;
    //padding-bottom: 45px;
    position: relative;
}
.signup-container {
    padding: 0px !important;
    margin: 0px !important;
    text-align: center;
    min-height: 1024px !important;
    max-width: 100% !important;
}
.signup-content {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 16px !important;;
    line-height: 26px !important;;
    align-items: center;
    color: #475569;
    display: block;
}
.side-box {
    background-color: #FFFF;
    border-radius: 0px 0px 120px 0px;
    box-shadow: 0px 8px 32px 0px #0000000F;
    min-height: 1024px !important;
}
.side-img {
    width: 430px;
    margin-bottom: 24px !important;
    margin-top: 152px !important;
}
.signup-form-container{
    background-color: #FFFF;
    max-width: 375px !important;
    margin: 50px auto 10px auto;
    padding: 20px;
    width: 100%;
    border-radius: 12px !important;
    box-shadow: 0px 8px 32px 0px #0000000F;

    .MuiTabs-root {
        border: 2px solid #5497FF;
        border-radius: 8px;
        margin: 30px 0px 12px 0px;
        text-align: center;
        padding: 2px;
    }
    .MuiTabPanel-root {
        padding: 0px;
    }
}
.signup-form {
    width: 100%;
}
.form-labels {
    font-family: 'Comfortaa-Bold' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #334155 !important;
    margin-top: 6px !important;
    text-align: left;
}
.text-box {
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
    display: flex;
    flex-direction: row;
    margin-top: 0px !important;
    padding: 10px 0px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #CBD5E1 !important;
    border-radius: 8px;
}
.active-tab {
    background-color: #5497FF !important;
    border-radius: 8px !important;
    color: white !important;
    text-transform: none !important;
    font-family: "Comfortaa-Bold" !important;
}
.deactive-tab {
    min-width: 50% !important;
    text-transform: none !important;
    color: #5497FF !important;
    border-radius: 8px !important;
}

.logo-item-signup {
    width: 228px;
    display: flex;
    margin: auto;
    cursor: pointer;
}
.logo-content-01 {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 13.7592px !important;;
    line-height: 18px !important;;
    display: flex;
    text-align: left;
    color: #475569;
    letter-spacing: -0.005em !important;;
    padding-left: 16px !important;
}
.logo-image-signup {
    width: 100px;
    margin-left: 14px !important;
}
.checkbox-label {
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #0F172A;
}
.checkbox-label-box {
   text-align: left !important;
}
.termsprivacy-label {
   font-family: "Nunito-Bold" !important;
}
.checkbox-label-signinbox {
    display: flex !important;
    justify-content: space-between !important;
}
.forgot-label {
    color: #334155 !important;
    cursor: pointer !important;

}
.checkbox-input {
    .MuiFormControlLabel-root {
        display: block;
    }
    .MuiCheckbox-root {
        color: #5497FF;
        margin: 3px 0px 0px 13px;
        width: 0px;
        height: 0px;
    }
}
.side-img-box {
    position: absolute;
    bottom: 92px;
    right: 10px;
}
.form-box {
    min-height: 1024px !important;
}
.info-img {
    position: absolute;
    right: 0px;
    top: 4px;
    cursor: pointer;
}
.btn-show-pass {
    cursor: pointer;
}
.info-msg {
    .MuiPaper-root {
        color: #64748B;
        font-family: "Nunito-Regular" !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        border-radius: 6px;
        padding: 16px;
    }
}
.reg-submit-btn {
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: uppercase !important;
    padding: 16px !important;
    width: 100%;
    margin-top: 15px !important;
    font-family: "Inter-Bold" !important;
}
.cont-disable-btn {
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    padding: 16px !important;
    margin-top: 15px !important;
    min-width: 208px !important;
    font-family: "Inter-Bold" !important;
    background-color: #9DC3FF !important;
    color: #F3F7FF !important;
}
.continue-btn {
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    padding: 16px !important;
    margin-top: 15px !important;
    min-width: 208px !important;
    font-family: "Inter-Bold" !important;

}
.btn-div {
    text-align: center !important;
}
.signin-input-box {
    margin-top: 0px !important;
}
.invissible-icon {
    position: absolute;
    top: 58px;
    right: 8px;
    width: 28px;
}
.vissible-icon {
    position: absolute;
    top: 58px;
    right: 8px;
    width: 28px;
}
.logo-item-signup-responsive {
    display: none;
}
.interest-box-in {
    padding: 34px;
}
.interest-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 20px !important;;
    line-height: 28px !important;;
    color: #475569;
    text-align: center;
}
.interest-sub-title {
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;;
    font-size: 16px !important;;
    line-height: 24px !important;;
    color: #475569;
    text-align: center;
    margin-top: 10px !important;
}
.interest-category {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 16px !important;;
    line-height: 28px !important;;
    color: #475569;
    margin-bottom: 10px !important;
}
.interest-block {
    padding: 24px;
    border-radius: 8px !important;
}
.chip-label {
    margin: 0px 8px 8px 0px !important;
    background-color: #F1F5F9 !important;
    color: #334155 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    font-family: "Nunito-Regular" !important;
    word-break: break-word !important;
    max-height: 100px !important;
    height: auto !important;
    min-height: 32px !important;
    @media (max-width: 450px) {
        max-height: 200px !important;
    }
    .MuiChip-label {
        white-space: normal !important;
    }}
.chip-label-selected {
    margin: 0px 8px 8px 0px !important;
    background-color: #5497FF !important;
    color: #FFFFFF !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    word-break: break-word !important;
    font-family: "Nunito-Regular" !important;
    max-height: 100px !important;
    height: auto !important;
    min-height: 32px !important;
    @media (max-width: 450px) {
        max-height: 200px !important;
    }
    .MuiChip-label {
        white-space: normal !important;
    }

}
.icon-style-selected {
    color: #FFFFFF !important;
    font-size: 16px !important;
}
.icon-style {
    color: #334155 !important;
    font-size: 16px !important;

}
.interest-heading-box {
    max-width: 700px;
    margin: 20px auto;
}
.cross-btn{
    padding: 12px 0px 12px 0px;
    font-size: 20px;
}
.add-more-text-box {
    display: flex;
    flex-direction: row;
    padding: 6px 0px !important;
    gap: 8px;
    background: #FFFFFF;
    border: none !important;
    min-width: 50px;
    .MuiInput-Underline {
        display: none !important;
    }
}
.error-msg {
    color: red;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: left;
} 
.interest-error-msg {
    color: red;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: center
}
.interest-form {
    position: relative;
    z-index: 9999;
}
.add-more-btn {
    color: #5497FF !important;
    background-color: white !important;
    box-shadow: none !important;
    padding: 10px 4px !important;
    .MuiButton-label {
        font-style: normal; 
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        font-family: 'Comfortaa-Bold' !important;
    }
}
.add-more-btn-div {
    text-align: right !important;
}
.other-fields {
    border: 1px solid #CBD5E1 !important;
    padding: 0px 16px 0px 16px !important;
    margin-top: 4px!important;
    max-height: 46px;
    border-radius: 8px !important;
}
.dropdown-box {
    text-align: left;
    border-radius: 8px !important;
    fieldset {
        border-color: #CBD5E1 !important;
        border-width: 1px !important;
        &:hover {
            border-color: #CBD5E1 !important;
            border-width: 1px !important;
        }
        &:focus {
            border-color: #CBD5E1 !important;
            border-width: 1px !important;
        }
    }
        legend {
            width: 0;;
        }
    
    .MuiSelect-select {
        position: relative;
        svg {
            display: none !important;
            position: absolute;
            padding-left: 10px;
        }
    > div {
        overflow: hidden;
        min-height: 1.1876em;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

}
.MuiMenu-paper {
    border-radius: 8px !important;
    .MuiMenu-list {
        width: 100% !important;
        padding: 0 !important;
        li {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
.dropdown-links {
    padding: 5px 7px;
    &:hover,
    &.Mui-selected {
        margin: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
    }
> div {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 5px 8px !important;
    width: 100%;
    &:hover,
    &.Mui-selected {
        color: #334155;
        border-radius: 4px !important;
        background-color: #f1f5f9 !important;
    }
}
svg {
    font-size: 18px;
}
&.Mui-disabled {
    display: none;
}
.MuiTouchRipple-root {
    display: none;
}

}
.interestimg{
 position: absolute;
 bottom: 0;
 right: 0;
 display:'block';
}
.active-done{
    display: block !important;
}
.deactive-done{
    display: none !important;
}
.active-lang-done{
    display: block !important;
}
.deactive-lang-done{
    display: none !important;
}
@media only screen and (max-width: 950px) {
    .side-box {
        background-color: transparent;
        border-radius: none;
        box-shadow: none;
        min-height: 320px !important;
    }
    .signup-content {
        display: none;
    }
    .active-tab {
        min-width: 50% !important;
        text-transform: none !important;
        font-family: "Comfotaa-Bold" !important;
    }
    .deactive-tab {
        min-width: 50% !important;
        text-transform: none !important;
        color: #5497FF !important;
    }
    .logo-item-signup-responsive {
        width: 100%;
        padding-top: 20px !important;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .logo-item-signup {
        display: none !important;
    }
    .side-img {
        width: 350px;
        margin-bottom: 0px !important;
        margin-top: 30px !important;
    }
    .side-img-div {
        width: 100%;
    }
    .signup-form-container{
       border-radius: 24px, 24px 0px, 0px !important;
    }
    .side-img-box {
        display: none !important;
    }
    .logo-image-signup {
        width: 54px;
    }
    .logo-content-01 {
        width: 115px;
    }
    .add-more-btn {
        padding: 4px 0px !important;
    }
    .interest-box-in {
        padding: 34px 20px;
    }
    .form-box {
        min-height: unset !important;   
    }
    .interestimg{
        display:none !important;
    }
}
@media only screen and (max-width: 350px) {
    .interestimg{
        display:none !important;
    }
    .side-img {
        width: 260px;
        margin-bottom: 0px !important;
        margin-top: 94px !important;
        margin-bottom: 0px !important;
        margin-top: 30px !important;
    }
    .chip-label {
        height: auto !important;
        width: 100% !important;
        margin: 0px 18px 18px 0px !important;
        
        .MuiChip-label {
            white-space: normal !important;
        }
    }
    .chip-label-selected {
        height: auto !important;
        width: 100% !important;
        margin: 0px 18px 18px 0px !important;
        .MuiChip-label {
            white-space: normal !important;
        }
    }
    .add-more-btn {
        padding: 8px 18px !important;

        .MuiButton-label {
           
            font-size: 9px !important;
            line-height: 9px !important;
            
        }
    }
}
.link-terms {
    text-decoration: none;
    color: #0F172A;
}
@media only screen and (max-width: 1024px) {
    .interestimg{
        display:none !important;
    }
}
@media only screen and (min-width: 768px) {
    .my-class {
        width: calc(100% - 15px);
        background: linear-gradient(179.98deg, #E7EFFC 0.01%, #FFFFFF 99.99%);
    }
}
