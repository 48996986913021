.header-box {
    background-color: #E8F0FC;
}
.logo-box {
    display: flex;
    width: 100%;
    background:linear-gradient(179.98deg, #E8F0FC 0.01%, #F7F9FC 99.99%);
    min-height: 518px;
}
.logo-container {
    padding: 10px 35px !important;
    max-width: 1200px !important;
}
.aboutus-container {
    padding: 10px 35px !important;
    max-width: 1200px !important;
    position: relative;
}
.community-Box {
    display: flex;
    width: 100%;
    background: linear-gradient(179.98deg, #F6F9FC 0.01%, #E7EFFC 99.99%);
    min-height: 320px;
    padding-bottom: 25px;
    position: relative;
}
.address-dummy-content {
    text-align: center;
    color: #475569;
    margin-top: 50% !important;
}
.address-sub-content {
    
}
.comingsoon-content {
    font-size: 22px !important;
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
}
.logo-item {
    width: 228px;
    display: flex;
}
.logo-content {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 13.7592px !important;;
    line-height: 18px !important;;
    display: flex;
    align-items: center;
    color: #475569;
    letter-spacing: -0.005em !important;;
    padding-left: 18px
}
.logo-image {
    width: 100px;
}
.welcome-content {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    color: #475569;
    letter-spacing: -0.005em !important;
    margin-top: 18px !important;
    font-family: "Nunito-Regular" !important;

}
.group-box {
    position: relative
}
.group-box-responsive {
    display: none !important;
}
.group-img {
    width: 700px;
    height: 400px;
    position: absolute;
    left: -85px;
    margin-top: 34px !important;
}
.welcome-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 44px !important;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em !important;
    color: #475569;
    margin-top: 34px !important;
    position: relative;
    z-index: 999;
}
.welcome-content1 {
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    color: #475569;
    letter-spacing: -0.005em !important;
}
.pocket-btn {
    margin-top: 24px !important;
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
}
.find-btn {
    margin-top: 24px !important;
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
}
.network-btn {
    margin-top: 24px !important;
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
}
.login-btn {
    margin-top: 24px !important;
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
}
.ellipse-div {
    position: absolute;
    width: 252px;
    height: 268px;
    left: -184px;
    top: 170px;
    background: #FFFFFF;
    border-radius: 50%;
}
.community-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 30px !important;;
    line-height: 40px !important;;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em !important;;
    color: #475569;
    position: relative;
    z-index: 999;
}
.community-sub-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    align-items: center;
    color: #475569 !important;
}

.community-second-sub-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    align-items: center;
    color: #475569 !important;
}
.community-card-div {
    margin-top: 14px !important;
    padding-bottom: 40px;
    position: relative;
    z-index: 999;
}
.community-card {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    align-items: center;
    color: #475569 !important;
    height: 280px;
    border-radius: 10px !important;
    box-shadow: 0px -1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    min-height: 280px;
    overflow: auto !important;
    scrollbar-width: none;
}
.community-second-card {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    align-items: center;
    color: #475569 !important;
    height: 280px;
    border-radius: 10px !important;
    box-shadow: 0px -1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    min-height: 280px;
    overflow: auto !important;
    scrollbar-width: none;
}
.community-content {
    font-family: "Nunito-Regular" !important;
    margin-top: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    align-items: center;
    color: #475569;
}
.community-words {
    color: #5497FF
}
.why-box {
    display: flex;
    width: 100%;
    background: linear-gradient(179.98deg, #E7EFFC 0.01%, #FFFFFF 99.99%);
    min-height: 320px;
    padding-bottom: 25px;
    position: relative;
}
.why-content-img1 {
    margin-top: 40px;
    margin-left: 77px;
    display: block !important;
}
.why-content-img2 {
    margin-left: 77px;
    display: block !important;
}
.why-title {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;;
    font-size: 40px !important;;
    line-height: 44px !important;;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em !important;;
    color: #475569;
    margin-top: 64px !important;
    position: relative;
    z-index: 999;
}
.why-content {
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;;
    font-size: 16px !important;;
    line-height: 24px !important;;
    display: flex;
    align-items: center;
    color: #475569;
    letter-spacing: -0.005em !important;;
    margin-top: 18px !important;
}
.why-content-second {
    font-family: "Nunito-Regular" !important;
    font-weight: 400 !important;;
    font-size: 16px !important;;
    line-height: 24px !important;;
    display: flex;
    align-items: center;
    color: #475569;
    letter-spacing: -0.005em !important;
    margin-top: 8px !important;
}
.aboutus-img {
    width: 100%;
}
.aboutus-div {
    margin: auto;
    width: 100%
}
.aboutus-content {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 500 !important;;
    font-size: 14px !important;;
    line-height: 24px !important;;
    display: flex;
    align-items: center;
    text-align: center;
    color: #334155;
}
.about-us-box {
    display: flex;
    width: 100%;
    background: linear-gradient(179.98deg, #FFFFFF 0.01%, #FBFCFE 99.99%);
    min-height: 320px;
    position: relative;
}
.aboutus-bg {
    background: linear-gradient(164.17deg, #F3F8FF -4.79%, #E7F4FF 111.32%);
    border-radius: 6px;
    position: absolute;
    height: 145px;
    width: 1200px;
    position: absolute;
    bottom: 110px;
    left: 0px;
}
.aboutus-btn {
    text-align: center;
    margin-bottom: 30px;
}
.contact-us-box {
    display: flex;
    width: 100%;
    background: #E8F0FC;
    min-height: 320px;
    position: relative;
}
.contact-div {
    margin-top: 14px !important;
    padding-bottom: 40px
}
.ellipse-div-second {
    position: absolute;
    width: 289px;
    height: 300px;
    left: -195px;
    top: 175px;
    background: #FFFFFF;
    border-radius: 50%;
}
.contactus-form {
    background-color: #FFFFFF;
    padding: 20px;
    position: relative;
    border-radius: 8px !important;
    min-height: 615px;
}
.address-form {
    background-color: #FFFFFF;
    padding: 20px;
    position: relative;
    min-height: 655px;
    border-radius: 8px !important;

}
.text-box {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #CBD5E1 !important;
    border-radius: 8px;
    margin-bottom: 6px !important;
}
.form-label {
    font-family: 'Comfortaa-Bold' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #334155;
    margin: 25px 0px 8px 0px !important;
}
.form-title {
    font-family: 'Comfortaa-Bold' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    letter-spacing: -0.005em;
    color: #475569;
    position: relative;
    z-index: 999;
}
.form-sub-title {
    margin-top: 8px !important;
    font-family: 'Nunito-Regular' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #475569;
}
.submit-btn {
    font-style: normal; 
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    padding: 16px !important;
    width: 100%;
    margin-top: 30px !important;
}
.contactus-container {
    padding: 20px !important;
    max-width: 1100px !important;
    position: relative;
    z-index: 9999;
}
.footer-box {
    display: flex;
    width: 100%;
    background: #5497FF;
}
.footer-container {
    padding: 10px 35px !important;
    max-width: 1200px !important;
    text-align: center;
}
.footer-content {
font-family: 'Nunito-Regular' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
line-height: 22px !important;
color: #FFFFFF;
}
.errorMsg {
    color: red;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    position: absolute;
    bottom: -20px;
}

.mapImage {
    margin-top: 10px !important;
}

.bubbleImg1 {
    position: absolute;
    top: -81px;
    left: 16px;
}
.bubbleImg2 {
    position: absolute;
    bottom: 0;
}
.bubbleImg3 {
    position: absolute;
    right: 51px;
    top: -26px;
}
.bubbleImg4 {
    position: absolute;
    right: -44px;
    bottom: 140px;
}
.underline1 {
    background-color: #B6D1FA;
    width: 193px;
    height: 12px;
    position: absolute;
    top: 62px;
}
.underline2 {
    background-color: #B6D1FA;
    width: 515px;
    height: 12px;
    position: absolute;
    top: 36px;
}
.underline3 {
    background-color: #B6D1FA;
    width: 105px;
    height: 12px;
    position: absolute;
    top: 104px;
}
.underline4 {
    background-color: #B6D1FA;
    width: 109px;
    height: 12px;
    position: absolute;
    top: 40px;
    left: 17px;
}
.underline5 {
    background-color: #B6D1FA;
    width: 141px;
    height: 12px;
    position: absolute;
    top: 40px;
}
.welcomeTextBox {
    position: relative;
}
.login-btn-responsive {
    display: none !important;
}
.why-content-img1-responsive {
    display: none !important;
}
.why-content-img2-responsive {
    display: none !important;
}
.bubbleImg2-responsive {
    display: none !important;
}
.bubbleImg1-responsive {
    display: none !important;
}
@media only screen and (max-width: 950px) {
    .bubbleImg3 {
        top: -43px;
        width: 80px;
    }
    .group-img {
        width: 100% !important;
        left: 0px;
        margin-top: 34px !important;
        position: unset;
        height: 100% !important;
    }
    .group-box {
        display: none;
    }
    .group-box-responsive {
        width: 100% !important;
        display: block !important;
    }
    .logo-box {
        min-height: 650px;
        padding-bottom: 45px;
    }
    .ellipse-div {
        display: none;
    }
    .logo-content {
        font-weight: 700 !important;
        font-size: 9.93px !important;
        line-height: 13px !important;
        padding-left: 8px;
        color: #FFFFFF !important;
    }
    .logo-item {
        width: 125px;
    }
    .logo-image {
        width: 86px;
    }
    .login-btn-responsive {
        margin-top: 0px !important;
        display: block !important;
        font-style: normal; 
        font-weight: 700 !important;
        font-size: 9px !important;
        line-height: 20px !important;
        text-transform: uppercase !important;
        color: #5497FF !important;
        background-color: #FFFFFF !important;
        width: 83px;
        height: 32px;
        padding: 6px 12px 6px 12px !important;
    }
    .login-btn{
        display: none !important;
    }
    .header-box {
        background-color: #5497FF !important;
    }
    .welcome-title {
        font-size: 30px !important;
        line-height: 40px !important;
        letter-spacing: -0.5% !important;
    }
    .underline1 {
        width: 148px;
    }
    .underline2 {
        display: none !important;
    }
    .network-btn {
        width: 100% !important;
    }
    .network-btnbox {
        width: 100% !important;
    }
    .community-Box {
        padding: 0px !important;
    }
    .why-responsive {
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px -1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
        padding: 14px;
        position: relative;
    }
    .why-content-img1-responsive {
        display: block !important;
        height: 200px !important;
        margin: 16px auto !important;
    }
    .why-content-img2-responsive {
        display: block !important;
        height: 200px !important;
        margin: auto !important;
    }
    .why-content-img1 {
        display: none !important;
    }
    .why-content-img2 {
        display: none !important;
    }
    .why-title {
        margin-top: 24px !important;
    }
    .underline3 {
        margin-top: 168px !important;
    }
    .find-btn {
        width: 100% !important;
    }
    .aboutus-btn {
        width: 100% !important
    }
    .aboutus-bg {
        display: none !important;
    }
    .pocket-btn {
        font-size: 12px !important;
    }
    .bubbleImg2 {
        display: none !important;
    }
    .bubbleImg2-responsive {
        display: block !important;
        position: absolute;
        right: 19px;
        top: -45px;
        transform: rotate(-4deg);
    }
    .bubbleImg1-responsive {
        display: block !important;
        position: absolute;
        top: -349px;
        transform: rotate(101deg);
        right: 10px;
    }
    .bubbleImg1 {
        display: none !important;
    }
    .bubbleImg4 {
        position: absolute;
        right: 5px;
        bottom: -31px;
    }
    .footer-box {
        position: relative;
    }
    .contactus-container {
        padding-bottom: 16px;
    }
    .community-card {
        height: auto;
    }
    .community-second-card {
        height: auto;
    }
}

.footer-img {
    width: 70% 
}

@media (max-width: 767px) {
    .footer-img {
        width: 80%
    }
}