

.react-tel-input {
    .selected-flag{
        border-radius: '8px 0px 0px 8px';
        &:hover{
            border-radius: 8px 0px 0px 8px;
            background-color: #f5f5f5;
        }
        &:focus{
            border-radius: 8px 0px 0px 8px;
            background-color: #f5f5f5;
        }
    }
    .flag-dropdown {
        &.open {
            .selected-flag {
                border-radius: 8px 0px 0px 8px;
                background-color: #f5f5f5;
            }
        }
    }
    .country-list {
        outline: none;
        z-index: 1;
        list-style: none;
        position: absolute;
        padding: 0;
        margin: 1px 0 0px 0px;
        background-color: white;
        width: 300px;
        box-shadow: none;
        max-height: 200px;
        overflow-y: scroll;
        border-radius: 8px;
        border: 1px solid #ccc;
        &::-webkit-scrollbar {
            width: 3px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #CFCFCF;
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-track {
            background-color: #F1F5F9;
            border-radius: 4px;
        }
    }

}

.heading_title {
    color: #475569 !important;
    font-size: 14px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    margin: 0px 0px 5px !important;
}
