.member_directory_page {
  margin-left: auto;
  margin-right: auto;
  padding-top: 130px;
  max-width: 1400px;
  justify-content: center;
  display: flex;
  padding-left: 16px; 
  padding-right: 16px; 
  @media (max-width: 960px) {
    padding-top: 16px !important;
    padding-left: 0 !important; 
    padding-right: 0 !important; 
  }
}

.members_directory_list {
  border-radius: 8px !important;
  gap: 20px !important;
  padding: 16px !important;
}

.md_header_box {
  margin-bottom: 16px !important;
}

.md_header {
  font-family: "Nunito-Regular" !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
}

.md_sub_header {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;

}

.member_count {
  color: #475569;
  font-size: 14px;
  text-align: center;
  font-family: "Nunito-Regular";
  font-weight: 400;
  line-height: 18px;
  margin-left: 8px !important;
}

.members_count_row {
  margin-top: 12px;
  display: flex
}

.members_count_block {
  display: flex
}

.search_icon_grid {
  justify-content: flex-end
}

.member_card_box {
  border-top: 1px solid #ccc;
  margin-top: 16px;
  padding-top: 16px;
  cursor: pointer !important;
}

.member_intro {
  display: flex;
  align-items: center;
}

.member_name {
  justify-content: flex-start;
  margin-left: 16px;
}

.md_location_info {
  display: flex;
}

.md_name {
  color: #475569;
  font-size: 16px;
  font-family: "Nunito-Regular" !important;
  font-weight: 600;
  line-height: 24px;
  word-break: break-all;
}

.md_location_text {
  color: #475569;
  font-size: 12px;
  text-align: center;
  font-family: "Nunito-Regular" !important;
  font-weight: 400;
  line-height: 22px;
  margin-left: 8px !important;
}

.mission_title {
  color: #475569;
  font-size: 12px;
  font-family: "Nunito-Regular";
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 4px !important; 
}

.skils_grid {
  padding-left: 0px;
  padding-right: 0px;
}

.skills_button {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-right: 0px !important;
  padding: 5px 12px 5px 10px !important;
  border-radius: 40px !important;
  color: white !important;
  background-color: #5497FF !important;
  margin: 5px 0px 5px 0px !important;
  word-break: break-all;
}

.plus_skills {
  font-family: "Comfortaa-Bold" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding-left: 0px !important;
  margin-left: 8px !important;
  align-items: center;
}

.skills_text {
  color: #64748B;
  font-size: 12px;
  font-family: "Nunito-Regular" !important;
  font-weight: 600;
  line-height: 18px;
  margin-left: 5px !important;
}

.mission_description {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  text-wrap: wrap !important;
  word-break: break-word !important;

  p{
    margin: 0 !important
  }
}

.mission_box{
  margin-left: 10px !important;
  margin-top: 8px !important;
  display: flex;
}

.oas_box{
  margin-left: 10px !important;
  display: flex;
}

.member_secondary_info {
  margin-Bottom: auto !important;

  @media (min-width: 600px) {
    margin-bottom: 8px;
  }
}

.filter_clear_grid {
  justify-content: space-between !important;
  align-items: center !important;
}

.filter_text {
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #475569 !important;
  margin-left: 8px !important;
}

.clear_all_text {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  cursor: pointer !important;
}

.clear_all_text_blur {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  cursor: pointer !important;
  opacity: 0.5 !important;
}

.dropdown_lang_options {
  cursor: pointer !important;
  width: 100% !important;
  .MuiTypography-displayBlock {
    word-break: break-word !important;
    font-family: "Nunito-Regular" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #475569 !important;
  }
}


.dropdown_set {
  margin-top: 10px;
  

}

.dd_headers {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #475569 !important;
}

.members_filter_box {
  border-radius: 8px !important;
  gap: 20px !important;
  padding: 16px !important;
  background-color: #FFFFFF !important;
}

.dd_container {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #E8EBF0;
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.dd_container_open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #F1F5F9;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  margin-top: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dropdown {
  border-top: 1px solid #E8EBF0;
  max-height: 180px;
  overflow-y: auto;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}

.dd_box {
  border-radius: 8px;
  padding: 2px 12px;
  cursor: pointer;
  border: 1px solid #E8EBF0;
  margin-top: 8px;
}

.dd_input {
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #475569 !important;
  cursor: pointer;
  width: 100%;
}

.dd_icon {
  color: #98A7C1;
}

.option {
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

.member_details_container{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #CBD5E1 !important;
  padding-bottom: 12px !important;
  cursor: pointer;
  @media (min-width: 660px) {
    border-bottom: none;
  }
}

.arrow_button{
  font-size: 24px;
  color: #475569;
}

.member_details_text {
  color: #475569 !important;
  font-size: 18px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  margin-left: 8px !important;
}

.md_first_title{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;

  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.md_first_value{
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: flex-end !important;
  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.md_first_text
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
  word-break: break-word !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.md_view_skills_chips {
  margin: 0px 8px 8px 0px !important;
  background-color: #5497FF !important;
  color: #FFFFFF !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: "Nunito-Regular" !important;
  word-break: break-all !important;
  text-overflow: none !important;
  height: auto !important;
  max-height: 45px !important;
  min-height: 28px !important;
  .MuiChip-label {
      white-space: normal !important;
      @media (max-width: 450px){
          font-size: 12px !important;
          line-height: 14px !important;
          word-break: break-all !important;
          text-overflow: none !important;
      }
  }
  @media (max-width: 450px){
      padding: 6px 3px !important;
      height: auto !important;
      max-height: 45px !important;
  }

}

.md_location_text
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
  padding-bottom: 8px !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.md_location_text2{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.md_skills_grid{
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: flex-end;
  @media(max-width: 600px){
    padding-top: 0px !important;
    padding-bottom: 12px !important;
  }
}

.md_skills_container{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-right: 0px !important;
  padding: 5px 12px 5px 10px !important;
  border-radius: 40px !important;
  color: white !important;
  background-color: #5497FF !important;
  margin: 5px 0px 5px 0px !important;
}

.md_show_less{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding: 0px !important;
  margin: 0px 8px 8px 0px !important
}

.skills_show_less{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding-left: 0px !important;
  margin-left: 4px !important;
}

.group_show_less{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding-left: 0px !important;
  margin-top: -8px !important;
}

.md_affilation_text{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  text-wrap: wrap !important;
  word-break: break-word !important;
  @media(max-width: 600px){
    font-size: 14px !important;
  }
}

.md_details_container{
  margin-top: 16px !important;
  @media (max-width: 660px) {
    margin-top: 16px !important;
  }
}

.md_summer_experience_header
{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  margin-bottom: 8px !important;
  margin-left: 8px !important;
}

.md_groups_text
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  margin-left: 16px !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.group_title_text
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.md_group_container{
  padding-bottom: 18px;
  padding-left: 0px;
  padding-right: 0px;
  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

@media screen and (max-width:540px) { 
  .container-hobby-details{
    overflow-x: auto !important;
  }
}

.container-hobby-details::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.userName{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #475569 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block !important;
  word-break: break-word !important;
  max-width: 200px !important;

  @media(max-width: 1064px){
    max-width: 180px !important;
  }

  @media(max-width: 450px){
    max-width: 180px !important;
  }

  @media(max-width: 400px){
    max-width: 130px !important;
  }

  @media(max-width: 350px){
    max-width: 90px !important;
  }
}