.mainContainer {
  background-color: #f5f5ff;
  width: 100% !important;
}

.gridContainerCM {
  margin: 120px 0 !important;

  @media (max-width: 959px) {
    margin: 15px 0 !important;
  }
}

.filterContainer {
  display: flex !important;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.filterBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Bold" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.filterTextName {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Bold" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer !important;
  word-break: break-word;
}

.filterformcontrol {
  min-width: 140;
}

.filterinputLabel {
  color: #64748b;
  font-size: 14px;
}

.filterDivider {
  width: 100%;
  margin: 15px 0;
  background: var(--neutrals-cool-gray-200, #e2e8f0);
}

.FilterRedioGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.radioGroup {
  width: 100%;
}

.groupFollowContainer {
  display: flex !important;
  padding: 20px;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.Groupstofollow {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.followlistitem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.textFirst {
  color: var(--neutrals-cool-gray-600, #475569);
  text-align: center;
  font-family: "Comfortaa-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.textSecond {
  color: #475569 !important;
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;

  @media (max-width: 450px) {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
  }
}

.ViewAlltext {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.followitemdetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.joingroupbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.joinGroupContainer {
  display: flex;
  padding: 4px 16px 4px 12px;
  margin-left: 55px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 32px;
  border: 1px solid var(--neutrals-cool-gray-300, #cbd5e1);
  background: var(--basic-white, #fff);
  cursor: pointer;
}

.joingroupdivider {
  height: 1px;
  background-color: #cbd5e1;
  width: 100%;
}

.viewAllGroupContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.postContainer {
  display: inline-flex !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  width: 100% !important;
  max-width: 1600px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;

  @media (max-width: 450px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.postcontainerCard {
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-radius: 4px;
  flex-shrink: 0;
  background: var(--basic-white, #fff);
  box-shadow: 0px 1px 36px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 16px 0px 0px 0px;

  @media (max-width: 450px) {
    padding: 0px 0px 0px 0px !important;
  }
}

.ellipse {
  position: absolute;
  width: 86.5px;
  height: 86.5px;
  flex-shrink: 0;
  bottom: 0;
  left: 0;
}

.goodMorningBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  position: relative;
  margin: 0px 0px 0px 30px;
  @media (max-width: 450px) {
    margin: 0px 0px 0px 0px !important;
    max-width: 184px !important;
    padding-left: 16px !important;
  }
}

.morningTitle {
  color: #475569 !important;
  font-family: "Nunito-Regular" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  max-width: 330px !important;
  margin-bottom: 8px !important;

  @media (max-width: 899px) {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    margin-bottom: 0px !important;
  }
}

.selfieContainer {
  width: 162px;
  height: 162.905px;
  flex-shrink: 0;
  margin-right: 24px;

  @media (max-width: 450px) {
    width: 158px !important;
    height: 158px !important;
    margin-right: 0px !important;
  }

  // margin-top: 17px;
}

.introcard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.tagbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}

.tags {
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  border-radius: 32px;
  border: 1px solid var(--neutrals-cool-gray-300, #cbd5e1);
  background: var(--basic-white, #fff);
}

.tag {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.usercard {
  width: 100%;
  display: flex;
  border-radius: 12px;
  align-items: center;
  background: var(--basic-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer;
}

.usercardleft {
  display: flex;
  align-items: center;
  padding-left: 24px;

  @media (max-width: 899px) {
    padding-left: 10px;
  }
}

.userImage {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 48px;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: none !important;
}

.MuiInput-underline::after {
  border-bottom: none !important;
}

.inputField {
  width: 100%;
  margin-left: 20px !important;

  @media (max-width: 899px) {
    margin-left: 10px !important;
    font-size: 10px !important;
  }
}

.css-q0jhri-MuiInputBase-root-MuiInput-root {
  width: 100%;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #64748b !important;
}

.attachment {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: 15px;
  padding-right: 24px;

  @media (max-width: 899px) {
    margin-right: 0px;
    padding-right: 10px;
  }
}

.modal-attachment {
  display: inline-flex;
  align-items: center;
  gap: 8px !important;
  cursor: pointer;
  position: relative;
}

.attachmentText {
  color: var(--neutrals-cool-gray-500, #64748b);
  text-align: center;
  font-family: "Nunito-Regular" !important;
  // font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;

  @media (max-width: 899px) {
    font-size: 11px !important;
  }
}

.recentCardTitle {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.recentText {
  color: #475569 !important;
  font-family: "Nunito-Regular" !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
}

.translationBox {
  @media (min-width: 450px) {
    margin-top: 8px !important;
  }
}

.recentPostCard {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 0;
}

.recentPostBox {
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-left: 24px;
  padding-right: 15px;
}

.reply-loop-pd {
  padding-left: 80px;
}

.descriptionBox {
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.descText_nonHash {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  cursor: pointer !important;
  word-break: break-word !important;
}

.descText_hash {
  color: #5497ff !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  cursor: pointer !important;
  word-break: break-word !important;
}

.post_truncated_text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.descTitle {
  color: var(--neutrals-cool-gray-600, #475569);
  text-align: center;
  font-family: "Comfortaa-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.post_forum_counter_container {
  margin-top: -16px !important;
  z-index: 2 !important;
  padding-right: 28px !important;
  justify-content: flex-end !important;
}

.custom-multiselect {
  .searchWrapper {
    .chip {
      font-size: 12px !important;
      font-weight: 400 !important;
      font-family: "Nunito-Regular" !important;
      line-height: 22px !important;
      white-space: normal !important;
      text-overflow: ellipsis !important;
      word-break: break-word !important;
    }
  }
}

.multiSelectContainer {
  position: unset !important;
  @media (max-width: 767px) {
    width: 80% !important;
  }
  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.multiSelectContainer input {
  padding: 7px;
  font-size: 16px;
  font-weight: 400 !important;
  font-family: "Nunito-Regular" !important;
  color: rgba(100, 116, 139, 1);
}

.post_forum_counter_text {
  color: #64748b !important;
  font-size: 12px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.descText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  cursor: pointer;
  word-break: break-word;
}

.rtag {
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: var(--blue, #5497ff);
}

.rtagText {
  color: var(--basic-white, #fff);
  text-align: right;
  font-family: "Comfortaa-Regular" !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.group-tag-text {
  color: #5497FF;
  font-family: "Nunito-Regular" !important;
  font-size: 10px !important;
  font-style: normal !important;
  line-height: 18px !important;
}

.recentTags {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.likesCard {
  display: flex;
  width: 93%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-left: 24px;

  @media (max-width: 899px) {
    width: 88%;
  }
}

.likesCommentsContainer {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.likesCommentsButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.likesContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.likes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.likesText {
  color: var(--neutrals-cool-gray-500, #64748b);
  text-align: center;
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;

  @media (max-width: 390px) {
    font-size: 13px !important;
  }
}

.bar {
  width: 1px;
  height: 24px;
  background: #cbd5e1;
}

.lineDivider {
  width: 100%;
  background: #cbd5e1;
}

.commentDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.userNameComment {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.userText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Bold" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 22px !important;
}

.time {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.break {
  // word-break: break-all;
  line-break: auto;
}

.word-break {
  word-break: break-word;
}

.doComment {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--neutrals-cool-gray-50, #f8fafc);
}

.postImageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.postImage {
  width: 100%;
  object-fit: cover;
  min-height: 300px;
  max-height: 300px;

  @media (max-width: 767px) {
    max-height: 150px;
    min-height: 150px;
    height: 150px;
  }
}

.postImageBoxpdf {
  min-width: 350px;
  max-width: 350px;

  @media (max-width: 767px) {
    min-width: 95%;
    max-width: 95%;
  }
}

.videoBox {
  max-height: 280px;
  object-fit: contain;
}

.fitBox {
  max-height: 400px;
  object-fit: contain;
}

.postImageBox {
  position: relative;
  width: 100%;
  // height: 100%;
  display: flex;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  min-height: 300px;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    max-height: 150px;
    min-height: 150px;
    height: 150px;
  }
}

.doc-box {
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #6be795;
}

.PostImageLeft {
  padding: 15px;
  border-right: 1px solid gray;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    border: none;
  }

  @media (max-width: 899px) {
    padding: 5px;
  }
}

.grid-layout-box {
  .PostImageRight {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.PostImageRight {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width: 899px) {
    padding: 5px;
  }
}

.shadowBox {
  // width: max-content;
  height: max-content;
  cursor: pointer;
  border-radius: 8px;
  // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.40);

  @media (max-width: 959px) {
    box-shadow: none;
  }
}

.documentName {
  font-size: 12px !important;
  color: #000000bf;
}

.DownloadBox {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7b7b7b;
  border-radius: 10px;
}

.overlay {
  width: 100%;
  height: 100%;
  content: "";
  display: flex;
  background-color: rgba(1, 1, 1, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: absolute;
  top: 0;
  font-size: 24px;
  color: #fff;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:before {
  border-color: #fff !important;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root:after {
  border-color: #fff !important;
}

.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
  border-color: #fff !important;
}

.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  border-color: #fff !important;
}

audio::-webkit-media-controls-panel {
  background-color: white;
}

.MuiMenuItem-root.Mui-selected {
  margin: 0 !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  margin: 0px !important;
  border-radius: 0px !important;
}

.mobileMenuHide {
  @media (max-width: 959px) {
    display: none;
  }
}

.morning-text {
  @media (max-width: 899px) {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
}

.responsiveGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 14px !important;

  @media (min-width: 899px) {
    display: none;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.arrowRedirect {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.titleT {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Regular" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 22px !important;
}

.viewText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.groupCard {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}

.cards {
  display: flex;
  width: 136px;
  padding: 12px 8px 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.joinButton {
  display: flex;
  width: 90%;
  padding: 8px 16px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--neutrals-cool-gray-100, #f1f5f9);
  background: var(--neutrals-cool-gray-100, #f1f5f9);
}

.userData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.userDataName {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
}

.titleTSmall {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Regular" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.filterformMenuList {
  > div {
    text-align: right;
    width: 100px;
    // min-width: 110px !important;

    :focus {
      background-color: transparent;
    }
  }

  ul {
    padding: 0;
  }

  @media (max-width: 899px) {
    font-size: 10px !important;
  }
}

.filterformMenu {
  color: #475569 !important;
  display: flex;
  gap: 4px;
  border-bottom: 1px solid #e2e8f0 !important;
  font-size: 12px !important;
  max-width: 96px;
  min-height: unset !important;
  padding: 5px 0 !important;

  &:last-child {
    border-bottom: none !important;
  }
}

.filterformMenuAttach svg {
  display: none;
}

.icon-gap {
  margin-right: 5px;
}

.modal-title {
  display: flex;
  align-items: center;
  font-family: "Comfortaa-Regular" !important;
  justify-content: space-between;
  background: var(--neutrals-cool-gray-100, #f1f5f9);
  padding: 20px;
  height: 30px;

  h3 {
    font-size: 18px;
    color: gray;
    margin: 0 !important;
  }
}

.modal-body {
  .profile-wrap {
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    color: #475569;
    font-size: 18px;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .input-box {
    width: calc(100% - 140px);

    @media (max-width: 575px) {
      height: 320px;
    }

    .input {
      height: 46px;
      width: 100%;
      padding: 20px 70px;
      // box-shadow: rgba(148, 163, 184, 0.3) 0px -1px 0px 0px;
      overflow: hidden;

      @media (max-width: 575px) {
        padding: 20px !important;
      }
    }
  }
}

.modal-footer {
  padding: 20px;
  min-height: 30px;
  background: #f1f5f9;

  button {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 16px;
    float: right;

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    background: none;
  }
}

.web-post-btn {
  display: block !important;

  @media (max-width: 575px) {
    display: none !important;
  }
}

.modal-footer-responsive {
  padding: 20px;
  min-height: 30px;
  display: none !important;

  button {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 16px;
    float: right;

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    background-color: #f1f5f9;
    display: block !important;
  }
}

.postDetailsContainer {
  display: inline-flex !important;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 959px) {
    padding: 0 !important;
  }
}

.replied-box {
  display: flex;
  gap: 12px;
}

.reply-btn {
  cursor: pointer;
}

.report-btn {
  cursor: pointer;
  margin-top: 6.5px;
}

.report-modal {
  position: absolute;
  right: 5%;
  z-index: 9;
  top: 30px;
  display: flex;
  width: 129px;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-gray-100, #f1f5f9);
  background: #fff;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
}

.new-report-modal {
  z-index: 9;
  display: flex;
  width: 110px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-gray-100, #f1f5f9);
  background: #fff;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
}

.report-text {
  color: var(--neutrals-cool-gray-600, #475569);
  cursor: pointer;
  text-align: center;
  font-family: "Inter-Bold" !important;
  font-size: 10px !important;
}

.postDetailsBlock {
  width: 100%;
}

// .backBtnBlock {
//     border-bottom: 1px solid #CBD5E1;
//     margin: 16px;
// }

.backBtn {
  color: #475569 !important;
  font-family: "Comfortaa-Bold" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.recentReplyPostBox {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-left: 80px;
  padding-right: 0px;
}

.backbtnicon {
  width: 20px;
  margin-right: 6px;
  color: #1e293b;
}

.modal-main {
  ::-webkit-scrollbar {
    display: none;
  }

  .modal-body {
    .attachmentText {
      font-size: 16px !important;
    }

    .input-box {
      width: 90%;
      height: 125px;
      margin: auto;

      .input {
        width: 100%;
        height: 100%;
        padding: 12px 0 !important;
        margin-left: 15px;

        @media (max-width: 768px) {
          margin-left: 0;
        }
      }

      textarea {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 85% !important;
  }

  @media (max-width: 575px) {
    width: 80% !important;
    top: 50% !important;
    transform: unset;
    left: 0;
    border-radius: 0;
    max-height: 80% !important;
  }
}

.media-modal-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;
}

.seeMoreText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.849);

  &:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
}

.recent-title-box {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.recentGroupText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Bold" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 22px !important;
}

.recentGroupTextSecond {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
  display: flex;
  justify-content: center;
  margin: 2px !important;
  gap: 2px;
  cursor: pointer !important;
}

.viewArrow {
  cursor: pointer !important;
}

.dropdown-box {
  text-align: left;
  border-radius: 8px !important;
  margin: -4px !important;
  height: 46px;

  .MuiSelect-select {
    &:focus {
      background: none !important;
    }
  }

  fieldset {
    border-color: #cbd5e1 !important;
    border-width: 1px !important;

    &:hover {
      border-color: #cbd5e1 !important;
      border-width: 1px !important;
    }

    &:focus {
      border-color: #cbd5e1 !important;
      border-width: 1px !important;
    }
  }

  legend {
    width: 0;
  }

  .MuiSelect-select {
    position: relative;

    svg {
      display: none !important;
      position: absolute;
      padding-left: 10px;
    }

    > div {
      overflow: hidden;
      min-height: 1.1876em;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.MuiMenu-paper {
  border-radius: 8px !important;

  .MuiMenu-list {
    width: 100% !important;
    padding: 0 !important;

    li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.dropdown-links {
  padding: 5px 7px;

  &:hover,
  &.Mui-selected {
    margin: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  > div {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 5px 8px !important;
    width: 100%;

    &:hover,
    &.Mui-selected {
      color: #334155;
      border-radius: 4px !important;
      background-color: #f1f5f9 !important;
    }
  }

  svg {
    font-size: 18px;
  }

  &.Mui-disabled {
    display: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.Carousel-root-31 {
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 25%;
  width: 100%;
  max-width: 500px;
  overflow: visible !important;

  @media (max-width: 767px) {
    width: 85%;
    max-width: 85%;
  }

  .videoBox {
    max-height: 200px;
    min-height: 200px;
  }

  .Carousel-indicators-125 {
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.Carousel-root-31 img {
  width: 100%;
  display: block;
  height: auto;
  max-height: 200px;
  min-height: 200px;
  object-fit: contain;

  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.Carousel-root-31 > div:nth-child(2) {
  button {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
}

.Carousel-root-31 > div:nth-child(3) {
  button {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
}

.carousel-btn {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 120px;
  left: 0;
  right: 0;
  top: 55%;
  margin: 0 auto;
  align-items: center;
  gap: 60px;

  @media (max-width: 767px) {
    height: 65px;
  }
}

.modal-btn-text {
  color: white;
}

.icon-modal {
  display: flex;
  gap: 8px;
  width: max-content;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close_btn_wrp {
  position: relative;

  svg {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 10px;
  }
}

.close_btn_wrp_doc_only {
  position: absolute;
  z-index: 99;
  top: 10px;
  right: 10px;
}

.rad-cls {
  border-radius: 10px;
}

.disable-post-btn {
}

.backdrop-loader {
  z-index: 9999 !important;
  color: #fff;
}

.search-details-box {
  width: 100%;
  min-height: 500px;
  height: auto;
}

.active-chip-content {
  justify-content: center !important;
  margin: 12px 12px 12px 0px !important;
  min-width: 50% !important;
  min-height: 40px !important;
  background-color: #5497ff !important;
  color: white !important;
  text-transform: none !important;
  font-family: "Comfortaa-Bold" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1px solid #5497ff !important;
}

.chip-content {
  justify-content: center !important;
  margin: 12px 12px 12px 0px !important;
  min-width: 50% !important;
  min-height: 40px !important;
  background-color: white !important;
  color: #5497ff !important;
  text-transform: none !important;
  font-family: "Comfortaa-Bold" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1px solid #5497ff !important;
}

.chip-box {
  display: flex;
  justify-content: center;
  margin-bottom: 24px !important;
}

.search-container {
  margin: 8px !important;
}

.user-list-name {
  font-family: "Comfortaa-Bold" !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: rgb(71, 85, 105);
  cursor: pointer !important;
}

.user-list-avatar {
  cursor: pointer !important;
}

.no-found-container {
  padding: 0px !important;
  height: 100%;
  width: 100%;
}

.no-found-box {
  width: 100%;
  height: 100%;
  background: #e7effc;
}

.upper-img {
  position: absolute;
  top: 125px;
  left: 45px;

  @media (max-width: 959px) {
    display: none;
  }
}

.lower-img {
  position: absolute;
  bottom: 45px;
  right: 45px;

  @media (max-width: 959px) {
    display: none;
  }
}

.center-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-img {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.no-found-text {
  max-width: 480px;
  text-align: center;
  margin-top: 40px;
}

.img-2-search {
  width: 164px;
  height: 254px;

  @media (max-width: 959px) {
    display: none;
  }
}

.no-search-text-one {
  color: var(--neutrals-cool-gray-600, #475569);
  text-align: center;
  // font-family: Inter;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px !important;
  /* 133.333% */
  letter-spacing: -0.12px;

  @media (max-width: 959px) {
    font-size: 22px !important;
  }
}

.optionListContainer {
  position: absoulte !important;
  width: 46% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;
  // margin-top: -310px !important;
  bottom: 90%;
  left: 20px;
  z-index: 1000 !important;
  @media (max-width: 600px) {
    width: 200px !important;
  }
}

.share_modal_box {
  background: #ffffff !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-width: 505px !important;
  border-radius: 8px !important;
  outline: "none";

  @media (max-width: 600px) {
    width: 340px !important;
  }
}

.share_text {
  color: #ffffff !important;
  font-size: 16px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.share_box {
  padding: 16px 24px !important;
  background: #5497ff !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.link_text {
  color: #475569 !important;
  font-size: 12px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  word-break: break-all !important;
}

.copy_text {
  color: #5497ff !important;
  font-size: 14px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-left: 8px !important;
}

.copy_container {
  align-items: center !important;
  display: flex !important;
  cursor: pointer !important;
  justify-content: flex-end !important;
}

.share_info_container {
  padding: 24px !important;
}

.link_container {
  width: 300px !important;
}

.copy_url_container {
  padding: 12px 16px !important;
  display: flex !important;
  border-radius: 8px !important;
  background: #f1f5f9 !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: center !important;
}

.or_text {
  color: #475569 !important;
  font-size: 16px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.or_container {
  justify-content: center !important;
  margin: 24px 0px !important;
}

.share_to_resource_hub_btn {
  max-height: 44px !important;
  background-color: #5497ff !important;
  width: 100% !important;
}

.share_to_resource_hub_btn_blur {
  max-height: 44px !important;
  background-color: #5497ff !important;
  width: 100% !important;
  opacity: 0.5 !important;
}

.share_tp_resource_container {
  width: 100% !important;
}

.resource_hub_info_container {
  width: 100% !important;
  padding: 16px !important;
  background: #f1f5f9 !important;
  border-radius: 8px !important;
  margin: 16px 0px 0px 0px !important;
}

.description_section {
  margin-top: 16px !important;
}

.cancel_send_button_container {
  margin: 24px 0px 0px 0px !important;
  justify-content: space-between !important;
  width: 100% !important;
  display: flex !important;
}

.cancel_btn {
  max-height: 44px !important;
  background-color: #ffffff !important;
  width: 100% !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-color: #5497ff !important;
}

.rh_send_btn_enable {
  max-height: 44px !important;
  background-color: #5497ff !important;
  width: 100% !important;
}

.rh_send_btn_disable {
  max-height: 44px !important;
  background-color: #5497ff !important;
  width: 100% !important;
  opacity: 0.7 !important;
}

.description_title_text {
  color: #475569 !important;
  font-size: 14px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.description_box {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 14px 14px !important;
  }

  display: flex;
  flex-direction: row;
  margin-top: 0px !important;
  padding: 10px 0px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #cbd5e1 !important;
  border-radius: 8px;
  margin-top: 4px !important;
}

.language_heading {
  color: #475569 !important;
  font-size: 14px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin-bottom: 5px !important;
}

.counter_text {
  color: #64748b !important;
  font-size: 12px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.counter_container {
  margin-top: -26px !important;
  z-index: 2 !important;
  padding-right: 8px !important;
  justify-content: flex-end !important;
}

.hide-scrollbar {
  overflow: hidden;
  /* Prevents scrollbar from appearing */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar for WebKit browsers (Chrome, Safari) */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Hides scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
  /* Hides scrollbar for Firefox */
}

.select-interest-post {
  position: relative !important;

  .optionListContainer {
    width: 100% !important;
    left: 0 !important;
  }
}
