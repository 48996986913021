.nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.item {
    position: relative;
    left: 20px;
    z-index: 1000
}

.middle-box {
    width: 185px;
    align-items: center;
    display: flex
}

.imgIcon {
    width: 135px;
}

.middle-text {
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;
    font-size: 11px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: "center" !important;
    color: #ffffff !important;
    letter-spacing: "-0.005em" !important;
    padding-left: 9px !important;
}

.right-icons {
    position: relative;
    right: 20px;
}

.Menu_dropdown {
    display: flex;
    position: absolute;
    z-index: 2000;
    right: 0;
    top: 0;
    width: 129px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--neutrals-cool-gray-200, #E2E8F0);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.Menu_items {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    cursor: pointer;
}

.Seprate_items {
    color: var(--neutrals-cool-gray-600, #475569);
    text-align: center;
    font-family: "Nunito-Regular" !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.dropdown-show {
    display: flex;
    width: 100%;
    max-width: 1550px;
    background: black;
    min-height: 100%;
    z-index: 9999;
    background-color: black;
    margin: 0 auto;
    position: relative;
}

.dropdown-hide {
    display: none;
}
.dropdown-header  .MuiMenu-paper {
        width: 100% !important;
        max-width: 1550px !important;
        margin: 0px auto !important;
        display: flex !important;
        right: 0px !important;
        background: transparent !important;
        box-shadow: none !important;
        justify-content: flex-end !important;
        top: 95px !important;
        left: 0 !important;
        overflow: visible !important;
    
    }
    .dropdown-header  .MuiMenu-paper ul {
        max-width: 129px !important;
        background: #fff;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        transform: translate(50%);
    }


.MuiMenuItem-root:hover {
    margin: 0 !important;
}

.searchBox {
    width: 300px !important;
    @media (max-width: 1160px){
        width: 200px !important;
    }
    @media (max-width: 1024px){
        width: 150px !important;
    }
}

.search-wrapper {
    .MuiInputBase-input::placeholder {
        opacity: 1 !important;
        color: var(--neutrals-cool-gray-500, #64748B) !important;
    }
}