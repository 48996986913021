
.main-container{
    max-width: 100% !important; 
    justify-content: center !important;
}

.paper-main{
    padding: 40px 10px 10px 10px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    display: flex !important;
    flex-direction: column !important;
    border-radius: 12px !important;
}

.main-div {
   
    display: flex;
    justify-content: center;
    background: linear-gradient(179.98deg, #E8F0FC 0.01%, #F7F9FC 99.99%);
    padding-top: 50px;
    padding-bottom: 50px
}
.grid-container {
    overflow: hidden;
    width: 100%; /* container width */
    height: auto; /* container height*/
    column-width: 400px;
  }
  
  .grid-container > span > p {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    font-family: "Nunito-Regular" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #475569;
  }
  .tc-img {
    
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    
  }
  .img-div {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;

  }
  .title-heading {
    font-size: 24px !important;
    font-family: "Comfortaa-Bold" !important;
    line-height: 32px !important;
    font-weight: 700 !important;
    color: #475569 !important;
    margin-top: 24px !important;
  }
