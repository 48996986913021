.private_ind_msg_text {
    font-family: "Nunito-Regular" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #475569 !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    max-width: 350px !important;
  
    @media(max-width: 450px){
      max-width: 250px !important;
    }
  
    @media(max-width: 400px){
      max-width: 200px !important;
    }
  
    @media(max-width: 350px){
      max-width: 100px !important;
    }
  }

.pvt_container {
    margin: 16px 0px !important;
    display: flex !important;
}

.pvt_counter_container{
  margin-top: -26px !important;
  z-index: 2 !important;
  padding-right: 19px !important;
  justify-content: flex-end !important;
}

.pvt_counter_text {
  color: #64748B !important;
  font-size: 12px !important;
  font-family: "Nunito-Regular" !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}


.pvt_container_last {
    margin: 16px 0px 0px 0px !important;
    display: flex !important;
}

.name_time_cont{
    display: flex !important;
    justify-content: space-between !important;
}

.pvt_msg_name{
    font-family: "Nunito-Regular" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #475569 !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    display: block !important;
    max-width: 500px !important;
  
    @media(max-width: 450px){
      max-width: 180px !important;
    }
  
    @media(max-width: 400px){
      max-width: 130px !important;
    }
  
    @media(max-width: 350px){
      max-width: 90px !important;
    }
}

.pvt_msg_whole_cont{
    border-radius: 8px !important;
    gap: 20px !important;
    padding: 24px !important;

    @media(max-width: 450px){
        padding: 16px !important;
      }
}

.msg_info_cont{
    margin: 0px 0px 0px 16px !important;
    width: 89% !important;

    @media(max-width: 450px){
        width: 83% !important;
        margin: 0px 0px 0px 8px !important;
      }
    
      @media(max-width: 400px){
        width: 80% !important;
      }
    
      @media(max-width: 350px){
        width: 78% !important;
      }
}

.pvt_msg_created_time{
    font-family: "Nunito-Regular" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #475569 !important;
}

.pvt_msg_avatar {
    height: 56px !important;
    width: 56px !important;

    @media(max-width: 450px) {
        height: 48px !important;
        width: 48px !important;
    }
}


.pvt_msg_user_last_cmnt{
    font-family: "Nunito-Regular" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #475569 !important;
    margin: 4px 0px 0px 0px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    display: block !important;
    max-width: 900px !important;
  
    @media(max-width: 1000px){
        max-width: 600px !important;
      }

    @media(max-width: 450px){
      max-width: 290px !important;
    }
  
    @media(max-width: 400px){
      max-width: 240px !important;
    }
  
    @media(max-width: 350px){
      max-width: 190px !important;
    }
}

.pvt_msg_user_cmnt{
    font-family: "Nunito-Regular" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #475569 !important;
    margin: 4px 0px 0px 0px !important;
    word-break: break-word !important;
    display: block !important;
    max-width: 900px !important;
  
    @media(max-width: 1000px){
        max-width: 600px !important;
      }

    @media(max-width: 450px){
      max-width: 290px !important;
    }
  
    @media(max-width: 400px){
      max-width: 240px !important;
    }
  
    @media(max-width: 350px){
      max-width: 190px !important;
    }
}

.pvt_msg_box {
    background: #FFFFFF !important;
    @media(max-width: 450px) {
        width: 85% !important;
    }
}

.pvt_head_box {
    padding: 20px 16px !important;
    background: #5497FF !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.pvt_head_title {
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-family: "Nunito-Regular" !important;
    font-weight: 600 !important;
    line-height: 22px !important;
}

.pvt_chat_box {
    width: 100% !important;
    .MuiInputBase-multiline {
        padding: 0px !important;
        padding-top: 8px !important;
        scrollbar-width: none !important;
        .MuiInputBase-input{
            scrollbar-width: none !important;
        }
    }
    
}

.cancel_send_button_cont {
    padding: 8px 16px 8px 16px !important;
    justify-content: space-between !important;
    width: 100% !important;
    display: flex !important;
    background: #F8FAFC !important;
    border-radius: 0px 0px 16px 16px !important;
}

.dialog_cont{
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin: 16px !important;
    gap: 16px !important;
}

.pvt_cancel_btn {
    max-height: 44px !important;
    background-color: #FFFFFF !important;
    width: 100% !important;
    border-radius: 8px !important;
    border-width: 2px !important;
    border-color: #5497FF !important;
}

.pvt_send_btn_enable {
    max-height: 44px !important;
    background-color: #5497FF !important;
    width: 100% !important;
}

.pvt_send_btn_disable {
    max-height: 44px !important;
    background-color: #5497FF !important;
    width: 100% !important;
    opacity: 0.7 !important;
}

.loaderBtn {
  padding: 22px !important ;
  border-radius: 8px !important;
  .makeStyles-root {
    background-color: #5477ff !important;
    border-radius: 8px !important;
  };
  .MuiCircularProgress-colorPrimary {
    width: 30px !important;
    height: 30px !important;
    color: #fff !important
  }
  .makeStyles-circularContainer {
    top: 50%
  }
}