 .btn-class {
   border-bottom: 1px solid #ccc;
   padding-bottom: 12px;
   padding-top: 12px;
   width: 100%;
   cursor: pointer;
   text-align: left;
   justify-content: left;
 }

 .hoverable {
  // border-bottom: 1px solid #ccc ;
   width: 100%;
   cursor: pointer;

   &:hover,
   &:active {
     font-weight: bold;
     color: #5497FF !important;
   }
 }
// .mobile-sidebar::-webkit-scrollbar{
//   display: none;
// }
// .mobile-sidebar {
//   scrollbar-width: none;
// }
 .trending-desc{
  font-family: 'Nunito-Regular';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #475569;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
 }

 .active-sidebar {
  border-left: 2px solid #5497FF !important;
  padding-left: 10px !important;
  font-weight: bold !important;
  color: #5497FF !important;
 }

 .hoverable-responsive {
  min-width: unset !important;
   cursor: pointer;
   width: 100%;
  .MuiButton-label {
    width: max-content;
  }
   &:hover,
   &:active {
     background-color: #5497FF !important;
   }
 }

 .button-text {
   color: #64748B;
   &:hover,
   &:active {
     color: #ffffff !important;
   }

 }

 //Sidebar Media Query css

 @media (max-width: 959px) {
   .mobile-view {
     display: block;
   }

   .header-section {
     display: none;
   }
 }

 @media (min-width: 960px) {
   .mobile-view {
     display: none;
   }

   .header-section {
     display: block;
     z-index: 9999;
   }
 }


 // Header Css 

 .logo_Text {
   font-family: "Comfortaa-Bold" !important;
   font-weight: 700 !important;
   font-size: 13.7592px !important;
   line-height: 18px !important;
   display: flex !important;
   align-items: 'center' !important;
   color: #ffffff !important;
   letter-spacing: '-0.005em' !important;
   padding-left: 18px !important;
 }

 .icon_main{
  display: column !important;
  flex-direction: column !important; 
  margin-left: 10px !important;
 }

 .Text_icon_first {
   color: #ffffff !important;
   margin-bottom: 2px !important;
   font-family: "Nunito-Regular" !important;
   font-size: 14px !important;
   margin-right: 20px !important;
 }

 .Resource_Hub_WebText {
  color: #ffffff !important;
  margin-bottom: 2px !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  margin-right: 20px !important;
}

.Members_Directory_WebText {
  color: #ffffff !important;
  margin-bottom: 2px !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  margin-right: 20px !important;
}

 .Text_icon_sec {
   color: #ffffff !important;
   margin-bottom: 2px !important;
   font-family: "Nunito-Regular" !important;
   font-size: 14px !important;
 }

 .Authore_img_main{
  border-radius: 50% !important;
   overflow: hidden !important;
   margin-left: 16px !important;
   cursor: pointer;
 }

 .header_icon_first{
  color: #ffffff !important;
  padding: 8px !important; 
  width: 24px;
height: 24px;
display: flex;
align-content: center;
justify-content: center;
align-items: center;
  margin-left: 12px;
  img {
    width: 16px !important;
    height: 17.5px !important;
  }
 }

 .Groups_Web_Icon{
  color: #ffffff !important;
  padding: 8px !important; 
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 26px;
  img {
    width: 16px !important;
    height: 17.5px !important;
  }
 }

.Resource_Hub_WebIcon{
  color: #ffffff !important;
  padding: 8px !important; 
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
    margin-left: 45px !important;
    img {
      width: 16px !important;
      height: 17.5px !important;
    }
 }

 .Members_Directory_WebIcon{
  color: #ffffff !important;
  padding: 8px !important; 
  width: 24px;
  height: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
    margin-left: auto !important;
    img {
      width: 16px !important;
      height: 17.5px !important;
    }
 }

 .header_icon_second{
   color: #ffffff !important;
   padding: 8px !important;
   margin-Left: auto !important;
   margin-right: 20px !important;
 }

.sidebar-bottom {
  border-bottom: 1px solid #ccc;
}

 @media (max-width: 959px) {
   .hamburger-icon {
     display: block;
   }

   .header-section {
     display: none;
   }
 }

 @media (min-width: 960px) {
   .hamburger-icon {
     display: none;
   }

   .header-section {
     display: block;
   }
 }


 